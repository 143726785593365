import React, { useState, useEffect, useCallback, useRef } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import "./styles.scss";
import Page from "../../components/Page";
import ActionHeader from "../../components/ActionHeader";
import Divider from "../../components/Divider";
import StatusButton from "../../components/StatusButton/index";
import TablePagination from "../../components/TablePagination";
import Icon from "./../../components/Icon/index";
import ConfirmationModal from "../../components/ConfirmationModal";
import { Location } from "../../services/Location";
import locationTypes from "../../constants/locationTypes";
import Container from "../../components/Container";
import LocationStatus from "../../components/LocationStatus";
import { CSVLink } from "react-csv";
import Button from "../../components/Button";

// createTheme creates a new theme named solarized that overrides the build in dark theme
createTheme(
  "solarized",
  {
    text: {
      primary: "white",
    },
    background: {
      default: "#013131",
    },

    divider: {
      default: "#002626",
    },
  },
  "dark"
);

const ColumnComponent = ({ title, subTitle }) => {
  return (
    <div className="column-component">
      <p className="column-title">{title}</p>
      <p className="column-sub-title">{subTitle}</p>
    </div>
  );
};

const initalColumns = {
  Type: { omit: false, dbName: "type" },
  Location: { omit: false, dbName: "name" },
  MSA: { omit: false, dbName: "msa" },
  City: { omit: false, dbName: "city" },
  "Post Code": { omit: false, dbName: "postal_code" },
  Added: { omit: false, dbName: "createdAt" },
  "CCS 60kW": { omit: true, dbName: "ccs60kW" },
  "CCS 90kW": { omit: true, dbName: "ccs90kW" },
  "CCS 120kW": { omit: true, dbName: "ccs120kW" },
  "CCS 175kW": { omit: true, dbName: "ccs175kW" },
  "CCS 180kW": { omit: true, dbName: "ccs180kW" },
  "CCS 350kW": { omit: true, dbName: "ccs350kW" },
  "CCS 360kW": { omit: true, dbName: "ccs360kW" },
  "Type 2 22kW": { omit: true, dbName: "type222kW" },
  "CHAdeMO 60kW": { omit: true, dbName: "chademo60kW" },
  "CHAdeMO 80kW": { omit: true, dbName: "chademo80kW" },
  "CHAdeMO 90kW": { omit: true, dbName: "chademo90kW" },
  "CHAdeMO 100kW": { omit: true, dbName: "chademo100kW" },
  "CHAdeMO 120kW": { omit: true, dbName: "chademo120kW" },
  CCS: { omit: false, dbName: "ccs" },
  CHAdeMO: { omit: false, dbName: "chademo" },
  "Type 2": { omit: false, dbName: "type2" },
  "Moderation status": { omit: false, dbName: "status" },
  Status: { omit: false, dbName: "type_status" },
};

const Locations = () => {
  const [omitColumn, setOmitColumn] = useState(
    JSON.parse(JSON.stringify(initalColumns))
  );
  const navigate = useNavigate();
  const csvLink = useRef();
  // redux states
  const token = useSelector((state) => state.auth.token);
  const userData = useSelector((state) => state.auth.user);

  const [page, setPage] = useState(1);
  const [noOfRows, setNoOfRows] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [searchField, setSearchField] = useState("");
  const [searchText, setSearchText] = useState("");
  const [pending, setPending] = useState(false);
  const [currentData, setCurrentData] = useState([]);
  const [sort, setSort] = useState("");
  const [orderType, setOrderType] = useState("asc");
  const [showRowsDropdown, setShowRowsDropdown] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [from, setFrom] = useState(0);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [selectedRows, setSelectedRows] = useState(false);
  const [toggledClearRows, setToggleClearRows] = useState(false);

  const [tableSettingsOpen, setTableSettingsOpen] = useState(false);

  useEffect(() => {
    setPending(true);
    fetchLocations().finally(() => {
      setPending(false);
    });
  }, [page, searchText, noOfRows, update]);

  useEffect(() => {
    if (sort.length > 0) {
      fetchLocations();
    }
  }, [sort, orderType]);

  async function fetchLocations() {
    return Location.Fetch_Locations(
      token,
      page,
      noOfRows,
      searchText,
      sort,
      orderType
    )
      .then(({ data }) => {
        setCurrentData(data.data);
        if (totalCount === 0) {
          setTotalCount(data.totalRows);
        }
        if (page > 1) {
          setFrom((page - 1) * noOfRows + 1);
        } else {
          setFrom(1);
        }
      })
      .catch((error) => console.log("error here", { error }));
  }

  const tableSettingsClick = () => {
    setTableSettingsOpen(!tableSettingsOpen);
  };
  const refreshTable = () => {
    setUpdate(!update);
    setTableSettingsOpen(false);
  };
  const resetSorting = () => {
    setSort("");
    setOrderType("asc");
    refreshTable();
  };
  const resetFilters = () => {
    setSearchField("");
    setTableSettingsOpen(false);
  };
  // set search text
  const setSearchInput = (event) => {
    setSearchField(event.target.value);
  };

  // debounce function to wait for a little time after user stop writing and then run setSearchInput function to search location
  const debouncedChangeHandler = useCallback(debounce(setSearchText, 300), []);

  useEffect(() => {
    debouncedChangeHandler(searchField);
  }, [searchField]);

  const onHandlePageClick = (selectedPage) => {
    setPage(selectedPage);
  };

  const onDeleteLocation = () => {
    Location.Location_Delete(token, selectedLocationId)
      .then((res) => {
        setShowConfirmationModal(false);
        refreshTable();
        toast.success("Location Deleted Successfully");
      })
      .catch((err) => {
        setShowConfirmationModal(false);
        toast.error(err.response.data.msg);
      });
  };
  const onRestoreLocation = () => {
    Location.Location_restore(token, selectedLocationId)
      .then((res) => {
        setShowConfirmationModal(false);
        refreshTable();
        toast.success("Location Restored Successfully");
      })
      .catch((err) => {
        toast.error(err.response.data.msg);
      });
  };

  const onLocationClick = (row) => {
    navigate(`/edit-location/${row.id}`, {
      state: {
        index: 0,
      },
    });
  };
  const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div style={{ marginTop: "0.5rem" }}>
      <input type="checkbox" ref={ref} onClick={onClick} {...rest} />
    </div>
  ));

  const handleSelectedRowChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  // Toggle the state so React Data Table changes to clearSelectedRows are triggered
  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
    setOmitColumn(JSON.parse(JSON.stringify(initalColumns)));
  };

  const columns = React.useMemo(
    () => [
      {
        name: <ColumnComponent title={"Type"} />,
        selector: (row) => row.type,
        cell: (row) => (
          <Icon icon={locationTypes[row.type]?.marker} size={30} />
        ),
        grow: 1,
        sortable: true,
        sortField: "type",
        omit: omitColumn["Type"].omit,
      },
      {
        name: <ColumnComponent title={"Location"} />,
        selector: (row) => row.name,
        grow: 17,
        sortable: true,
        sortField: "name",
        omit: omitColumn["Location"].omit,
      },
      {
        name: <ColumnComponent title={"MSA/Retailer"} />,
        selector: (row) => row.msa,
        cell: (row) => {
          if (row.msa) {
            return <img src={row.msa.dark_Logo} width={60} alt="type" />;
          }
        },
        grow: 4,
        sortable: true,
        sortField: "msa.name",
        omit: omitColumn["MSA"].omit,
      },
      {
        name: <ColumnComponent title={"City"} />,
        selector: (row) => row.city,
        grow: 3,
        sortable: true,
        sortField: "city",
        omit: omitColumn["City"].omit,
      },
      {
        name: <ColumnComponent title={"Post Code"} />,
        selector: (row) => row.postal_code,
        grow: 3,
        sortable: true,
        sortField: "postal_code",
        omit: omitColumn["Post Code"].omit,
      },
      {
        name: <ColumnComponent title={"Added"} />,
        selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
        grow: 3,
        sortable: true,
        sortField: "createdAt",
        omit: omitColumn["Added"].omit,
      },
      {
        name: <ColumnComponent title={"CCS"} subTitle={"60kW"} />,
        selector: (row) => row.ccs60kW,
        grow: 1,
        omit: omitColumn["CCS 60kW"].omit,
      },
      {
        name: <ColumnComponent title={"CCS"} subTitle={"90kW"} />,
        selector: (row) => row.ccs90kW,
        grow: 1,
        omit: omitColumn["CCS 90kW"].omit,
      },
      {
        name: <ColumnComponent title={"CCS"} subTitle={"120kW"} />,
        selector: (row) => row.ccs120kW,
        grow: 1,
        omit: omitColumn["CCS 120kW"].omit,
      },
      {
        name: <ColumnComponent title={"CCS"} subTitle={"175kW"} />,
        selector: (row) => row.ccs175kW,
        grow: 1,
        omit: omitColumn["CCS 175kW"].omit,
      },
      {
        name: <ColumnComponent title={"CCS"} subTitle={"180kW"} />,
        selector: (row) => row.ccs180kW,
        grow: 1,
        omit: omitColumn["CCS 180kW"].omit,
      },
      {
        name: <ColumnComponent title={"CCS"} subTitle={"350kW"} />,
        selector: (row) => row.ccs350kW,
        grow: 1,
        omit: omitColumn["CCS 350kW"].omit,
      },
      {
        name: <ColumnComponent title={"CCS"} subTitle={"360kW"} />,
        selector: (row) => row.ccs360kW,
        grow: 1,
        omit: omitColumn["CCS 360kW"].omit,
      },
      {
        name: <ColumnComponent title={"Type 2"} subTitle={"22kW"} />,
        selector: (row) => row.type222kW,
        grow: 1,
        omit: omitColumn["Type 2 22kW"].omit,
      },
      {
        name: <ColumnComponent title={"CHAdeMO"} subTitle={"60kW"} />,
        selector: (row) => row.chademo60kW,
        grow: 1,
        omit: omitColumn["CHAdeMO 60kW"].omit,
      },
      {
        name: <ColumnComponent title={"CHAdeMO"} subTitle={"80kW"} />,
        selector: (row) => row.chademo80kW,
        grow: 1,
        omit: omitColumn["CHAdeMO 80kW"].omit,
      },
      {
        name: <ColumnComponent title={"CHAdeMO"} subTitle={"90kW"} />,
        selector: (row) => row.chademo90kW,
        grow: 1,
        omit: omitColumn["CHAdeMO 90kW"].omit,
      },
      {
        name: <ColumnComponent title={"CHAdeMO"} subTitle={"100kW"} />,
        selector: (row) => row.chademo100kW,
        grow: 1,
        omit: omitColumn["CHAdeMO 100kW"].omit,
      },
      {
        name: <ColumnComponent title={"CHAdeMO"} subTitle={"120kW"} />,
        selector: (row) => row.chademo120kW,
        grow: 1,
        omit: omitColumn["CHAdeMO 120kW"].omit,
      },
      {
        name: <ColumnComponent title={"CCS"} />,
        selector: (row) => row.ccs,
        grow: 1,
        omit: omitColumn["CCS"].omit,
      },
      {
        name: <ColumnComponent title={"CHAdeMO"} />,
        selector: (row) => row.chademo,
        grow: 1,
        omit: omitColumn["CHAdeMO"].omit,
      },
      {
        name: <ColumnComponent title={"Type 2"} />,
        selector: (row) => row.type2,
        grow: 1,
        omit: omitColumn["Type 2"].omit,
      },
      {
        name: <ColumnComponent title={"Moderation status"} />,
        selector: (row) => row.status,
        grow: 1,
        omit: omitColumn["Moderation status"].omit,
        sortable: true,
        sortField: "status",
      },
      {
        name: <ColumnComponent title={"Location Status"} />,
        cell: (row) => (
          <LocationStatus status={row.type_status?.toLowerCase()} />
        ),
        grow: 1,
        sortable: true,
        sortField: "type_status",
        omit: omitColumn["Status"].omit,
      },

      {
        cell: (row) => (
          <>
            {row.status !== "Deleted" ? (
              <Icon
                cursor={row.status !== "Deleted" && "pointer"}
                onClick={() => {
                  if (row.status === "Deleted") return;
                  setSelectedLocationId(row.id);
                  setShowConfirmationModal("delete");
                }}
                icon={"dustbin"}
                size={20}
              />
            ) : (
              <div className="restore">
                <button
                  onClick={() => {
                    setSelectedLocationId(row.id);
                    setShowConfirmationModal("restore");
                  }}
                >
                  Restore
                </button>
              </div>
            )}
          </>
        ),
        width: "4em",
        style: { paddingLeft: "1.52em" },
        omit: userData.role === "MEMBER",
      },
      {
        name: (
          <>
            <div className="export_button_div">
              <button onClick={() => csvLink.current.link.click()}>
                Export
              </button>
            </div>
            <div>
              <Icon
                icon={"vertical_dots"}
                onClick={tableSettingsClick}
                cursor={"pointer"}
                className="options_dots"
              />
            </div>
          </>
        ),
        cell: (row) => (
          <div className={row.status === "Deleted" ? "hideEdit" : ""}>
            <Icon
              cursor={"pointer"}
              icon={"vertical_dots"}
              onClick={() => onLocationClick(row)}
            />
          </div>
        ),
        width: "4em",
        style: { paddingLeft: "1.52em" },
      },
    ],
    [omitColumn, tableSettingsOpen]
  );

  const conditionalRowStyles = [
    {
      when: (row) => row.status === "Deleted",
      style: {
        opacity: 0.7,
      },
    },
  ];

  return (
    <div>
      {showConfirmationModal && (
        <ConfirmationModal
          showConfirmationModal={showConfirmationModal}
          setShowConfirmationModal={() => setShowConfirmationModal(false)}
          headingTitle={`Are you sure you want to ${showConfirmationModal} this Location?`}
          onClickNo={() => {
            setShowConfirmationModal(false);
          }}
          onClickYes={() => {
            if (showConfirmationModal === "delete") {
              onDeleteLocation();
            } else {
              onRestoreLocation();
            }
          }}
        />
      )}
      <Container
        showSearchBar
        onSearchChange={setSearchInput}
        inputValue={searchField}
        searchPlaceholder="Search for locations and charging stations"
      >
        <div className="location-container">
          <ActionHeader
            title={"Locations"}
            showButtons
            leftButtonTitle="Create"
            rightButtonTitle="GFX Sync"
            leftButtonDisable={true}
            rightButtonDisable={true}
          />
          <Divider />
          <div className="table-div">
            <DataTable
              conditionalRowStyles={conditionalRowStyles}
              className="rdt_Table"
              columns={columns}
              data={currentData}
              progressPending={pending}
              onSort={(column, sortDirection) => {
                setSort(column.sortField);
                setOrderType(sortDirection);
              }}
              sortServer
              highlightOnHover={true}
              onRowClicked={(row) => {
                if (row.status !== "Deleted") {
                  onLocationClick(row);
                }
              }}
              theme="solarized"
              responsive={true}
              pagination={true}
              paginationPerPage={noOfRows === "All" ? totalCount : noOfRows}
              selectableRowsComponent={Checkbox}
              onSelectedRowsChange={handleSelectedRowChange}
              clearSelectedRows={toggledClearRows}
              paginationComponent={({ currentPage, onChangeRowsPerPage }) => {
                return totalCount > 0 ? (
                  <TablePagination
                    page={page}
                    totalCount={totalCount}
                    itemsPerPage={noOfRows === "All" ? totalCount : noOfRows}
                    onHandlePageClick={(selectedPage) =>
                      onHandlePageClick(selectedPage)
                    }
                    from={from}
                    currentData={currentData}
                    setShowRowsDropdown={setShowRowsDropdown}
                    showRowsDropdown={showRowsDropdown}
                    noOfRows={noOfRows}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    setPage={setPage}
                    setNoOfRows={setNoOfRows}
                  />
                ) : (
                  <></>
                );
              }}
            />
            <div className="two_dots">
              {tableSettingsOpen && (
                <div className="table-settings">
                  <h4>Screen settings</h4>
                  <div className="table-columns">
                    <div className="options-container">
                      {Object.entries(omitColumn)
                        .slice(0, Object.keys(omitColumn).length / 2)
                        .map((item, index) => {
                          const column = item[0];
                          return (
                            <div className="option">
                              <p>{column}</p>
                              <input
                                type="checkbox"
                                checked={!item[1].omit}
                                onChange={(e) => {
                                  let tempOmitColumn = { ...omitColumn };
                                  tempOmitColumn[column].omit =
                                    !e.target.checked;
                                  setOmitColumn({ ...tempOmitColumn });
                                }}
                              />
                            </div>
                          );
                        })}
                    </div>
                    <div className="options-container">
                      {Object.entries(omitColumn)
                        .slice(Object.keys(omitColumn).length / 2)
                        .map((item, index) => {
                          const column = item[0];
                          return (
                            <div className="option">
                              <p>{column}</p>
                              <input
                                type="checkbox"
                                checked={!item[1].omit}
                                onChange={(e) => {
                                  let tempOmitColumn = { ...omitColumn };
                                  tempOmitColumn[column].omit =
                                    !e.target.checked;
                                  setOmitColumn({ ...tempOmitColumn });
                                }}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="divider"></div>
                  <div className="quick-options">
                    <h4 onClick={handleClearRows}>Clear selection</h4>
                    <h4 onClick={resetFilters}>Clear filters</h4>
                    <h4 onClick={resetSorting}>Reset sorting</h4>
                  </div>
                  <div className="divider"></div>
                  <div className="quick-options" onClick={refreshTable}>
                    <h4>Refresh table</h4>
                  </div>
                  <div className="divider"></div>
                  <div className="quick-options">
                    <h4 onClick={() => csvLink.current.link.click()}>
                      Export to CSV
                    </h4>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>

      <ToastContainer />
      <CSVLink
        ref={csvLink}
        filename={"location-data.csv"}
        data={currentData.map((item) => {
          //new location object with populated values and updated key names
          let singleLocation = {
            ...item,
            type: locationTypes[item?.type || 3]?.name,
            "Location Status": item?.type_status,
            "Moderation Status": item?.status,
            msa: item?.msa?.name,
            evses: JSON.stringify(
              item.evses.map((evs) => evs.evs_uid).join(" | ")
            ),
          };

          //delete non-required columns
          const deleteColumns = [
            "id",
            "image_path",
            "country_code",
            "source",
            "msaId",
            "cost",
            "is_locked",
            "is_top_level_lock",
            "status",
            "type_status",
          ];
          deleteColumns.forEach((col) => delete singleLocation[col]);
          return singleLocation;
        })}
      />
    </div>
  );
};

export default Locations;
