import React, { useState } from "react";
import DataTable from "react-data-table-component";
import "./styles.scss";
import Icon from "../../../components/Icon";
import Button from "../../../components/Button";
import ReactCalendar from "../../../components/ReactCalendar";
import moment from "moment";

const initialException = {
  date: moment().format("YYYY-MM-DD"),
  startBreak: "11:00",
  endBreak: "12:00",
  status: "Break",
  new: true,
};

const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const OpeningComponent = ({
  openingHours,
  exceptions,
  onOpeningHoursChange,
  onExceptionsChange,
}) => {
  const [activeOpeningIndex, setActiveOpeningIndex] = useState(-1);
  const [activeExceptionIndex, setActiveExceptionIndex] = useState(-1);
  const [newException, setNewException] = useState(null);
  const [showCalendarModal, setShowCalendarModal] = useState(false);

  const handleAddException = () => {
    setNewException({
      ...initialException,
    });
  };

  const openingColumns = [
    {
      name: "Day",
      selector: (data) => weekDays[data.day - 1],
      grow: 6,
    },
    {
      name: "Opening Time",
      selector: "openingHour",
      grow: 3,
      cell: (row, index) => {
        return (
          <input
            type="time"
            value={row.openingHour}
            disabled={index !== activeOpeningIndex}
            onChange={(e) => {
              const updatedData = [...openingHours];
              updatedData[index].openingHour = e.target.value;
              onOpeningHoursChange(updatedData);
            }}
          />
        );
      },
    },
    {
      name: "Closing Time",
      selector: "closingHour",
      grow: 3,
      cell: (row, index) => {
        return (
          <input
            type="time"
            value={row.closingHour}
            disabled={index !== activeOpeningIndex}
            onChange={(e) => {
              const updatedData = [...openingHours];
              updatedData[index].closingHour = e.target.value;
              onOpeningHoursChange(updatedData);
            }}
          />
        );
      },
    },
    {
      name: "Update",
      button: true,
      cell: (data, index) =>
        activeOpeningIndex === index ? (
          <Button
            onClick={() => {
              setActiveOpeningIndex(-1);
            }}
            title={"Save"}
          />
        ) : (
          <>
            <Button
              disabled={activeOpeningIndex !== -1}
              onClick={() => {
                setActiveOpeningIndex(index);
              }}
              title={"Edit"}
            />
          </>
        ),
      grow: 2,
    },
  ];

  const exceptionColumns = [
    {
      name: "Date",
      selector: (data) => data.date,
      grow: 4,
      cell: (data, index) => {
        if (
          !data.new &&
          (activeExceptionIndex === null || activeExceptionIndex !== index)
        ) {
          return data.date || "-";
        } else {
          return (
            <>
              <div
                className="calendar"
                onClick={() => setShowCalendarModal(data?.new ? "new" : "old")}
              >
                <div className="selected-date">
                  <>
                    <h4 className="month">
                      {moment((data?.new ? newException : data)?.date).format(
                        "YYYY-MM-DD"
                      )}
                    </h4>
                  </>
                </div>
                <div className="arrows">
                  <Icon icon={"up_arrow_small"} />
                  <Icon icon={"down_arrow_small"} />
                </div>
              </div>
            </>
          );
        }
      },
    },
    {
      name: "Start Break",
      selector: (data) => data.startBreak,
      grow: 3,
      cell: (data, index) => {
        return (
          <input
            type="time"
            disabled={
              !data.new &&
              (activeExceptionIndex === null || activeExceptionIndex !== index)
            }
            value={data.startBreak || ""}
            onChange={(e) => {
              if (data.new) {
                setNewException({
                  ...newException,
                  startBreak: e.target.value,
                });
              } else {
                const updatedData = [...exceptions];
                updatedData[activeExceptionIndex].startBreak = e.target.value;
                onExceptionsChange(updatedData);
              }
            }}
          />
        );
      },
    },
    {
      name: "End Break",
      selector: (data) => data.endBreak,
      grow: 3,
      cell: (data, index) => {
        return (
          <input
            type="time"
            disabled={
              !data.new &&
              (activeExceptionIndex === null || activeExceptionIndex !== index)
            }
            value={data.endBreak || ""}
            onChange={(e) => {
              if (data.new) {
                setNewException({
                  ...newException,
                  endBreak: e.target.value,
                });
              } else {
                const updatedData = [...exceptions];
                updatedData[activeExceptionIndex].endBreak = e.target.value;
                onExceptionsChange(updatedData);
              }
            }}
          />
        );
      },
    },
    {
      name: "Actions",
      grow: 3,
      cell: (data, index) => (
        <>
          {data.new ? (
            <div className="newExceptionActions">
              <Button
                onClick={() => {
                  setNewException(null);
                }}
                title={"Cancel"}
              />
              <Button
                onClick={() => {
                  const tempNewException = { ...newException };
                  delete tempNewException["new"];
                  onExceptionsChange([...exceptions, tempNewException]);
                  setNewException(null);
                }}
                title={"Save"}
              />
            </div>
          ) : activeExceptionIndex === index ? (
            <Button
              onClick={() => {
                setActiveExceptionIndex(-1);
              }}
              title={"Save"}
            />
          ) : (
            <>
              <Icon
                cursor={data.status !== "Deleted" && "pointer"}
                onClick={() => {
                  const tempExeptions = [...exceptions];
                  tempExeptions.splice(index, 1);
                  onExceptionsChange([...tempExeptions]);
                }}
                icon={"dustbin"}
                size={20}
              />
              <Button
                disabled={activeExceptionIndex !== -1}
                onClick={() => {
                  setActiveExceptionIndex(index);
                }}
                title={"Edit"}
              />
            </>
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <div className="table">
        <DataTable
          columns={openingColumns}
          data={openingHours}
          highlightOnHover={true}
          theme="solarized"
          noHeader={true}
        />
        <div className="exception">
          <p className="exceptionDescription">
            Make sure your regular opening hours are filled first, then add more
            hours if necessary. Opening hour exception can be used to define
            date/time intervals when the charging location is open or closed
            overwriting any regular opening hours that may have been defined for
            the charging location
          </p>
          <Button
            title={"Add Exceptions"}
            onClick={handleAddException}
            disabled={!!newException}
            outline
          />
        </div>
        <div className="table-div">
          {(!!newException ? [...exceptions, newException] : exceptions)
            .length > 0 && (
            <DataTable
              columns={exceptionColumns}
              data={!!newException ? [...exceptions, newException] : exceptions}
              highlightOnHover={true}
              theme="solarized"
              noHeader={true}
            />
          )}
        </div>
      </div>
      {!!showCalendarModal && (
        <div className="date-select-modal">
          <ReactCalendar
            fullScreen
            minDate={new Date()}
            setDate={(value) => {
              if (showCalendarModal === "new") {
                setNewException({
                  ...newException,
                  date: moment(value).format("YYYY-MM-DD"),
                });
              } else {
                const updatedData = [...exceptions];

                updatedData[activeExceptionIndex].date =
                  moment(value).format("YYYY-MM-DD");
                onExceptionsChange(updatedData);
              }
              setShowCalendarModal(false);
            }}
          />
        </div>
      )}
    </>
  );
};

export default OpeningComponent;
