import "react-calendar/dist/Calendar.css";
import "./styles.scss";
import React, { useState } from "react";
import Calendar from "react-calendar";

const ReactCalendar = ({
  date,
  setDate,
  range,
  fullScreen = false,
  ...props
}) => {
  const [value, onChange] = useState(new Date());

  const positionStyling = fullScreen
    ? {
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        margin: "auto",
      }
    : {
        top: "1rem",
        right: "1rem",
        bottom: 0,
      };

  return (
    <div
      className="react-calendar"
      style={{
        position: "absolute",
        zIndex: 9999,
        ...positionStyling,
      }}
    >
      <Calendar onChange={setDate} selectRange={range} {...props} />
    </div>
  );
};

export default ReactCalendar;
